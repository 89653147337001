import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import qs from "qs";

import type { RootState } from "../../../store";

export const shopifyGatewayApi = createApi({
  reducerPath: "shopify-gateway-api",
  baseQuery: async (arg, api, extraOptions) => {
    const { auth, config } = api.getState() as RootState;

    return fetchBaseQuery({
      paramsSerializer: qs.stringify,
      baseUrl: config.SHOPIFY_GATEWAY_URL,
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    })(arg, api, extraOptions);
  },
  endpoints: () => ({}),
});
