import { shopifyGatewayApi as api } from "./base.api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEstimatedDeliveryDate: build.mutation<
      GetEstimatedDeliveryDateApiResponse,
      GetEstimatedDeliveryDateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/estimated_delivery_date`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getShipmentProtectionCost: build.mutation<
      GetShipmentProtectionCostApiResponse,
      GetShipmentProtectionCostApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shipment_protection_cost`,
        method: "POST",
        body: queryArg,
      }),
    }),
    indexShopRedirects: build.query<
      IndexShopRedirectsApiResponse,
      IndexShopRedirectsApiArg
    >({
      query: () => ({ url: `/v1/shop_redirects` }),
    }),
    upsertShopRedirect: build.mutation<
      UpsertShopRedirectApiResponse,
      UpsertShopRedirectApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shop_redirects`,
        method: "POST",
        body: queryArg,
      }),
    }),
    deleteShopRedirect: build.mutation<
      DeleteShopRedirectApiResponse,
      DeleteShopRedirectApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shop_redirects/${queryArg}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as shopifyGatewayApi };
export type GetEstimatedDeliveryDateApiResponse =
  /** status 200 Get an estimated delivery date */ {
    data: {
      estimated_delivery_date: string;
      sku: string;
      quantity: number;
      reason: string;
    };
  };
export type GetEstimatedDeliveryDateApiArg = {
  data: {
    shop_name: string;
    destination_postal_code: string;
    cart: {
      sku: string;
      quantity: number;
    }[];
    from: string | null;
    only_enabled_skus?: boolean;
  };
};
export type GetShipmentProtectionCostApiResponse =
  /** status 200 Shipment protection cost data */
  | {
      data: {
        admin_fee: string;
        protection_fee: string;
        total_fee: string;
        mode: "opt_in" | "opt_out";
        checkout_message: string | null;
        provider: string;
        status: "available";
        token: string;
        units: number;
      };
    }
  | {
      data: {
        status: "available";
        mode: "total_coverage";
        checkout_message: string | null;
      };
    }
  | {
      data: {
        status: "unavailable";
      };
    };
export type GetShipmentProtectionCostApiArg = {
  data: {
    shop_name: string;
    destination_country_code: string;
    cart_value: string;
  };
};
export type IndexShopRedirectsApiResponse =
  /** status 200 Get all shop redirects */ {
    data: {
      shop: string;
      redirect_url: string;
    }[];
  };
export type IndexShopRedirectsApiArg = void;
export type UpsertShopRedirectApiResponse =
  /** status 204 The upsert was successful */ undefined;
export type UpsertShopRedirectApiArg = {
  shop: string;
  redirect_url: string;
};
export type DeleteShopRedirectApiResponse = unknown;
export type DeleteShopRedirectApiArg = string;
